<template>
  <section id="chart-groups">
    <div>
      <label for="group-field">
        {{ lang.surveyCharts.create.form.groups.field.label[languageSelected] }}
      </label>
      <multiselect
        id="group-field"
        class="new-group__fields"
        v-model="groupField"
        :options="surveyOptions"
        track-by="key"
        label="name"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        :placeholder="
          lang.surveyCharts.create.form.groups.field.placeholder[
            languageSelected
          ]
        "
        :disabled="groupCount > 0"
        @select="onFieldSelected"
      />
    </div>

    <div class="new-group">
      <vs-input
        v-model="groupName"
        :label="
          lang.surveyCharts.create.form.groups.name.label[languageSelected]
        "
        :placeholder="
          lang.surveyCharts.create.form.groups.name.placeholder[
            languageSelected
          ]
        "
        class="fill mt-6 new-group__name"
      />

      <div
        v-if="groupField && groupField.responseType === 3"
        class="mt-6 new-group__options"
      >
        <label for="group-field">
          {{
            lang.surveyCharts.create.form.groups.values.label[languageSelected]
          }}
        </label>
        <multiselect
          id="group-field"
          v-model="groupValues"
          :options="groupField.options"
          :searchable="true"
          :show-labels="false"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :placeholder="
            lang.surveyCharts.create.form.groups.values.placeholder[
              languageSelected
            ]
          "
          :limit="3"
          :limit-text="limitText"
        />
      </div>

      <vs-input
        v-if="groupField && groupField.responseType !== 3"
        v-model="groupValues"
        :label="
          lang.surveyCharts.create.form.groups.values.label[languageSelected]
        "
        :placeholder="
          lang.surveyCharts.create.form.groups.values.comma[languageSelected]
        "
        class="fill mt-6 new-group__options"
        @keyup.enter="addGroup"
      />

      <vs-button
        radius
        color="primary"
        class="add-group-btn"
        icon="add"
        @click.stop="addGroup"
        :disabled="!groupField || !groupName || !groupValues"
        v-show="groupField"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChartGroups',
  props: {
    value: Array,
    surveyOptions: {
      type: Array,
      required: true
    },
    disjoint: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      groupField: null,
      groupName: null,
      groupValues: null,
      groupCount: 0,
      selectedField: null,
      groups: []
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    limitText(count) {
      return `+${count}`
    },
    onFieldSelected(field) {
      this.selectedField = field
    },
    resetGroup() {
      this.groupName = null
      this.groupValues = null
    },
    existGroupName() {
      return this.groups.find(g => g.name === this.groupName)
    },
    addGroup() {
      if (this.existGroupName()) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.form.groups.existingGroup[
            this.languageSelected
          ],
          color: 'warning'
        })
        return
      }

      let values
      if (this.groupField.responseType === 3) {
        values = Object.values(this.groupValues)

        // remove values from options
        if (this.disjoint) {
          this.groupField.options = this.groupField.options.filter(
            o => !values.includes(o)
          )
        }
      } else {
        values = this.groupValues.split(',').filter(v => v !== '')
      }

      const group = {
        name: this.groupName,
        values
      }

      // keep local groups updated
      this.groups.unshift(group)

      this.$emit('onGroupAdd', { field: this.groupField.key, group })
      this.groupCount++
      this.resetGroup()
    },
    sortGroup() {
      const isNumber = /^\d+$/

      if (isNumber.test(this.groupField.options[0])) {
        this.groupField.options = this.groupField.options.sort((a, b) => a - b)
      }
    },
    removeGroup(groupIdx) {
      // return values to options
      if (this.disjoint) {
        if (this.groupField.options) {
          this.groupField.options = this.groupField.options.concat(
            this.groups[groupIdx].values
          )
          this.sortGroup()
        }
      }
      this.groups.splice(groupIdx, 1)
      this.groupCount--
    },
    reset() {
      this.groupField = null
      this.groupName = null
      this.groupValues = null
      this.groupCount = 0
      this.selectedField = null
      this.groups = []
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#chart-groups {
  label {
    font-size: 0.85rem;
    padding-left: 5px;
  }
  .new-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    &__name {
      flex-basis: 32%;
      margin-right: 15px;
    }

    &__options {
      flex-basis: 68%;
      margin-right: 54px;
      .multiselect {
        margin-top: 0;
        max-width: 255px;
      }
    }

    .add-group-btn {
      position: absolute;
      margin-top: 42px !important;
      right: 0;
    }
  }
}
</style>
